<template>
	<div>
		<i18n
			v-for="({ bold, href, stepLinkText }, key, index)
				in $t(`siteSettings.integrations.${id}.steps`)"
			:key="`${key}-${index}`"
			:path="`siteSettings.integrations.${id}.steps.${key}.text`"
			tag="p"
			class="z-body integration-info-text__text"
		>
			<template v-if="bold">
				<span
					v-for="boldText in bold"
					:key="`${$options.kebabCase(boldText)}-infotext`"
					class="z-body z-font-weight-bold"
					v-text="boldText"
				/>
			</template>
			<a
				class="z-body integration-info-text__link"
				:href="href"
				target="_blank"
			>
				{{ stepLinkText }}
			</a>
		</i18n>
	</div>
</template>

<script>
import { kebabCase } from '@/utils/object';

export default {
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	kebabCase,
};
</script>

<style lang="scss" scoped>
.integration-info-text {
	&__text {
		margin-bottom: 32px;
	}

	&__link {
		color: $primary;
	}
}
</style>
