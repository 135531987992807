const integrationsValidators = {
	googleAnalytics: {
		getIdFromScript: /ga\('create', '([^']*)', 'auto'\);/,
		idValidation: /UA-\w*-\w*|G-\w*/,
	},
	hotjar: {
		getIdFromScript: /h\._hjSettings={hjid:([^,]*)/,
		idValidation: /^\w{1,30}$/,
	},
	facebookPixel: {
		getIdFromScript: /fbq\('init', '([^']*)'\);/,
		idValidation: /^\w{1,30}$/,
	},
	facebookMessenger: {
		getIdFromScript: /page_id="([^"]*)"/,
		idValidation: /^\w{1,30}$/,
	},
	googleTagManager: {
		getIdFromScript: /\(window,document,'script','dataLayer','([^']*)'\);/,
		idValidation: /GTM-\w{1,30}/,
	},
};

const validateIntegrationInput = ({
	integrationId,
	inputValue,
}) => {
	if (inputValue === '') {
		return {
			value: inputValue,
			isValid: true,
		};
	}

	const {
		getIdFromScript,
		idValidation,
	} = integrationsValidators[integrationId];
	// Check if user has pasted in the whole script, and, if yes, get ID from it
	const searchScript = inputValue.match(getIdFromScript);
	const idFromInput = searchScript?.length >= 2 ? searchScript[1] : inputValue;
	const trimmedId = idFromInput.trim();
	const isValid = idValidation.test(trimmedId);

	return {
		value: trimmedId,
		isValid,
	};
};

export {
	integrationsValidators,
	validateIntegrationInput,
};
