<template>
	<section class="integration">
		<ZyroSettingsTitle>
			{{ $t('siteSettings.nav.integrations') }}
		</ZyroSettingsTitle>
		<div class="integration__container">
			<div class="integration__info">
				<ZyroText>
					<template #title>
						{{ $t('siteSettings.integrations.infoText.title') }}
					</template>
					<template #text>
						{{ $t('siteSettings.integrations.infoText.text') }}
					</template>
				</ZyroText>
			</div>
			<div class="integration__meta">
				<BannerIntegrations
					v-show="!hasActivePlan"
					@click="handleUpgradeClick"
				/>
				<ZyroCollapsible
					v-for="({
						icon, id, name, label, placeholder, type
					}, index) in integrations"
					:key="`${index}-integration-meta`"
					v-qa="`sitesettings-integrations-${id}`"
					:icon="icon"
					:is-open="isOpen[index]"
					@toggle="isOpen.splice(index, 1, !isOpen[index])"
				>
					<template #heading>
						{{ name }}
					</template>
					<template #tag>
						<ZyroStatus
							v-if="hasActivePlan"
							:text="$t('common.connect')"
							:status-done="!!inputValues[id].status"
							:text-done="inputValues[id].status"
						/>
						<ZyroPill v-else />
					</template>
					<template #content>
						<IntegrationInfoText :id="id" />
						<ZyroFieldText
							:data-qa="`integrations-inputfield-${id.toLowerCase()}`"
							class="integration__code"
							:input-tag="type || 'input'"
							:value="inputValues[id].value"
							:placeholder="placeholder || ''"
							:label="label || ''"
							label-theme="secondary"
							input-theme="secondary"
							:error="inputValues[id].error ? $t('validate.integrationIdInvalid') : ''"
							@input="onInput($event, id)"
						/>
						<ZyroNotification
							v-if="id === 'facebookMessenger'"
							mode="light"
							type="success"
							:message="$t('siteSettings.integrations.facebookMessenger.notification')"
						/>
					</template>
					<template
						v-if="$v.inputValues[id].$anyDirty"
						#footer
					>
						<Transition
							name="fade"
						>
							<ZyroButton
								theme="plain"
								class="integration__meta-footer"
								data-qa="integrations-btn-cancel"
								@click="resetKey = id, activeModalName = 'confirm'"
							>
								{{ $t('common.cancel') }}
							</ZyroButton>
						</Transition>

						<ZyroButton
							theme="primary"
							data-qa="integrations-btn-savechanges"
							@click="submit(id, index)"
						>
							{{ $t('common.saveChanges') }}
						</ZyroButton>
					</template>
				</ZyroCollapsible>
			</div>
		</div>
		<SiteSettingsModal
			v-if="activeModalName === 'confirm'"
			:title="$t('siteSettings.common.unsavedChanges')"
			:right-button-text="$t('common.discard')"
			:left-button-text="$t('common.continueEditing')"
			@close="activeModalName = ''"
			@left-button-click="activeModalName = ''"
			@right-button-click="activeModalName = '', reset(), resetKey = null"
		>
			{{ $t('siteSettings.common.unsavedChangesText') }}
		</SiteSettingsModal>
		<SiteSettingsModal
			v-if="activeModalName === 'premium'"
			:title="$t('siteSettings.integrations.modals.premium.title')"
			:right-button-text="$t('common.choosePlan')"
			@close="activeModalName = ''"
			@left-button-click="activeModalName = ''"
			@right-button-click="$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE })"
		>
			{{ $t('siteSettings.integrations.modals.premium.text') }}
		</SiteSettingsModal>
	</section>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import ZyroCollapsible from '@/components/site-settings/components/ZyroCollapsible.vue';
import ZyroNotification from '@/components/site-settings/components/ZyroNotification.vue';
import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import ZyroStatus from '@/components/site-settings/components/ZyroStatus.vue';
import ZyroText from '@/components/site-settings/components/ZyroText.vue';
import BannerIntegrations from '@/components/site-settings/pages/integrations/components/BannerIntegrations.vue';
import IntegrationInfoText from '@/components/site-settings/pages/integrations/components/IntegrationInfoText.vue';
import { validateIntegrationInput } from '@/components/site-settings/pages/integrations/integrationValidations';
import { SANITIZE_HEAD_CONFIG } from '@/constants/sanitizeConfig';
import { SUBSCRIPTION_SETTINGS_ROUTE } from '@/router';

export default {
	name: 'Integrations',
	components: {
		ZyroSettingsTitle,
		ZyroText,
		ZyroCollapsible,
		ZyroStatus,
		BannerIntegrations,
		SiteSettingsModal,
		ZyroNotification,
		IntegrationInfoText,
	},
	setup() {
		return { SUBSCRIPTION_SETTINGS_ROUTE };
	},
	data() {
		return {
			integrations: [
				{
					id: 'googleAnalytics',
					icon: 'analytics',
					name: this.$t('siteSettings.integrations.googleAnalytics.name'),
					label: this.$t('siteSettings.integrations.googleAnalytics.label'),
					placeholder: this.$t('siteSettings.integrations.googleAnalytics.placeholder'),
				},
				{
					id: 'hotjar',
					icon: 'hotjar',
					name: this.$t('siteSettings.integrations.hotjar.name'),
					label: this.$t('siteSettings.integrations.hotjar.label'),
					placeholder: this.$t('siteSettings.integrations.hotjar.placeholder'),
				},
				{
					id: 'facebookPixel',
					icon: 'fb-px',
					name: this.$t('siteSettings.integrations.facebookPixel.name'),
					label: this.$t('siteSettings.integrations.facebookPixel.label'),
					placeholder: this.$t('siteSettings.integrations.facebookPixel.placeholder'),
				},
				{
					id: 'facebookMessenger',
					icon: 'messenger',
					name: this.$t('siteSettings.integrations.facebookMessenger.name'),
					label: this.$t('siteSettings.integrations.facebookMessenger.label'),
					placeholder: this.$t('siteSettings.integrations.facebookMessenger.placeholder'),
				},
				{
					id: 'googleTagManager',
					icon: 'tag-manager',
					name: this.$t('siteSettings.integrations.googleTagManager.name'),
					label: this.$t('siteSettings.integrations.googleTagManager.label'),
					placeholder: this.$t('siteSettings.integrations.googleTagManager.placeholder'),
				},
				{
					id: 'customMeta',
					name: this.$t('siteSettings.integrations.customMeta.name'),
					type: 'textarea',
				},
			],
			inputValues: {},
			activeModalName: '',
			resetKey: null,
			showFooter: false,
			isOpen: [],
		};
	},
	validations() {
		return {
			inputValues: Object.keys(this.inputValues).reduce((accumulator, item) => ({
				...accumulator,
				[item]: { value: {} },
			}), {}),
		};
	},
	computed: {
		...mapState([
			'website',
			'hasActivePlan',
		]),
		...mapGetters(['websiteMeta']),
	},
	beforeMount() {
		const customMetaValue = this.$sanitize(this.websiteMeta?.customMeta || '', SANITIZE_HEAD_CONFIG);

		this.isOpen = new Array(Object.keys(this.integrations).length).fill(false);
		this.integrations.forEach((integration) => {
			const value = this.getIntegrationValue(integration.id);

			this.inputValues[integration.id] = {
				value: integration.id === 'customMeta' ? customMetaValue : value,
				status: value ? this.$t('common.completed') : '',
				error: false,
			};
		});
	},
	methods: {
		...mapMutations(['setWebsiteMeta']),
		...mapActions('subscription', ['autoConnectPlan']),
		async handleUpgradeClick() {
			await this.autoConnectPlan();
			if (!this.hasActivePlan) {
				this.$router.push({ name: SUBSCRIPTION_SETTINGS_ROUTE });
			}
		},
		async submit(id, index) {
			await this.autoConnectPlan();

			if (!this.hasActivePlan) {
				this.activeModalName = 'premium';

				return;
			}

			let isValid;
			let sanitizedValue;

			if (id !== 'customMeta') {
				const output = validateIntegrationInput({
					integrationId: id,
					inputValue: this.inputValues[id].value,
				});

				isValid = output.isValid;
				sanitizedValue = this.$sanitize(output.value);
			} else {
				sanitizedValue = this.$sanitize(this.inputValues[id].value, SANITIZE_HEAD_CONFIG);
				isValid = true;
			}

			if (!isValid) {
				this.inputValues = {
					...this.inputValues,
					[id]: {
						...this.inputValues[id],
						error: true,
					},
				};

				return;
			}

			this.setWebsiteMeta({
				key: id,
				value: sanitizedValue,
			});
			this.inputValues = {
				...this.inputValues,
				[id]: {
					status: sanitizedValue ? this.$t('common.saved') : '',
					value: sanitizedValue,
					error: false,
				},
			};
			this.$v.$reset();
			if (index) {
				this.isOpen.splice(index, 1, !this.isOpen[index]);
			}
		},
		reset() {
			const value = this.getIntegrationValue(this.resetKey);

			this.inputValues = {
				...this.inputValues,
				[this.resetKey]: {
					status: this.inputValues[this.resetKey].status,
					value,
					error: false,
				},
			};
			this.$v.$reset();
			this.showFooter = false;
		},
		getIntegrationValue(id) {
			return this.websiteMeta?.[id] || '';
		},
		onInput(event, id) {
			this.$v.inputValues[id].$touch();
			this.inputValues[id].value = event.target.value;
		},
	},
};
</script>

<style lang="scss" scoped>
.integration {
	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include mobile-view {
			flex-direction: column;
		}
	}

	&__info {
		width: 40%;
		margin-right: 40px;

		@include mobile-view {
			order: 2;
			width: 100%;
			margin-right: 0;
		}
	}

	&__meta {
		width: 60%;

		@include mobile-view {
			order: 1;
			width: 100%;
			margin-bottom: 32px;
		}

		&-footer {
			margin-right: 32px;

			@include mobile-view {
				margin-right: 24px;
			}
		}
	}

	&__code {
		font-family: monospace;
	}
}
</style>
