<template>
	<div class="banner">
		<h3 class="z-h5 z-font-weight-light">
			{{ $t('siteSettings.integrations.banner.title') }}
		</h3>
		<p class="z-body banner__text">
			{{ $t('siteSettings.integrations.banner.text') }}
		</p>
		<ZyroButton
			theme="primary"
			data-qa="bannerintegration-btn-upgradenow"
			@click="$emit('click')"
		>
			{{ $t('siteSettings.integrations.banner.button') }}
		</ZyroButton>
		<ZyroSvg
			name="integrations-blue"
			class="banner__decoration banner__decoration--top-left"
		/>
		<ZyroSvg
			name="integrations-yellow"
			class="banner__decoration banner__decoration--bottom-right"
		/>
		<ZyroSvg
			name="integrations-dots"
			class="banner__decoration banner__decoration--bottom-middle"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default { computed: mapState(['websiteId']) };
</script>

<style lang="scss" scoped>
.banner {
	position: relative;
	z-index: 2;
	padding: 54px 64px 72px 56px;
	margin-bottom: 16px;
	overflow: hidden;
	background-color: $light;

	@include mobile-view {
		margin-bottom: 8px;
	}

	&__text {
		margin-bottom: 40px;
	}

	&__decoration {
		position: absolute;
		z-index: -1;

		&--top-left {
			top: 0;
			left: 0;
		}

		&--bottom-middle {
			right: 5%;
			bottom: -12%;
		}

		&--bottom-right {
			right: 0;
			bottom: 0;
		}
	}
}

</style>
